.description-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: start;
}

.description-text {
    padding: 10px 10px;
    font-size: 1.2rem;
    color: white;
    opacity: 0.8;
    margin: 0px 20px 10px 20px;
    text-shadow: 0px 0px 2px white;
}

@media (min-width: 60rem) {
    .description-text {
        padding: 0px 50px;
        font-size: 1.2rem;
    }
  }
  