.social-icons-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;

}

.social-icons-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 20px;
    color: #191919;
    opacity: 0.8;
    text-shadow: 0px 0px 3px white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    text-decoration: none;
    color: white;
    font-size: 1rem;

}


.social-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}