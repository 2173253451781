.image-container {
    margin-top: 50px;
    margin-bottom: 35px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.image-container-img {
    width: 270px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 0px;
    box-shadow: 0 0 .1rem #fff,
    0 0 .1rem #fff,
    0 0 0.2rem #7fc7d9,
    0 0 0.1rem #7fc7d9,
    0 0 1.4rem #7fc7d9,
    inset 0 0 1.3rem #7fc7d9;
    animation: flickerLight 2s infinite;
}
.image-container-img-holographic {
    margin-top: -40px;
    width: 270px;
    height: 200px;
    position: absolute;
    object-fit: cover;
    background-color: #7FC7D9;
    opacity: 0.2;
    box-shadow: 0px 0px 10px #7FC7D9;
}
.image-container-light  {
    background-image: url('../../public/assets/under-light.svg');
    background-position: center center; 
    background-repeat: no-repeat;
    margin-top: 10px;
    width: 270px;
    height: 20px;
    opacity: 0.5;
    transition: all 0.5s ease;
    animation: flickerLight 2s infinite;
  }
.image-container-light-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container-light-glow {
    height: 1px;
    width: 96px;
    background-color: #7FC7D9;
    box-shadow: 0px 0px 10px 1px #fff;
    border: none;
    animation: flickerLight 0.8s;
}

@keyframes flickerLight {
    0% {opacity: 0.7;}
    25% {opacity: 0.8;}
    50% {opacity: 0.6;}
    60% {opacity: 0.75;}
    80% {opacity: 0.7;}
    100% {opacity:0.8;}
  }

.sceen-flicker-line {
    width: 270px;
    position: absolute;
    border-top: 1px solid white;
    animation: screenLine 2s infinite;
}

@keyframes screenLine {
    0% {opacity: 0; height: 0px;}
    5% {opacity: 0.8; height: 5px;}
    50% {opacity: 0.6;height: 100px;}
    60% {opacity: 0.75;}
    80% {opacity: 0.7;}
    100% {opacity:0.8; height: 200px;}
  }

.carousel-slide-link {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
    width: 270px;
    height: 200px;
    color: #ffffff;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: white;
    opacity: 0.7;
    text-decoration: none;
    cursor: pointer;
    z-index: 9;
    animation: flickerLight 0.8s;
}

.carousel-slide-link:focus {
    outline: 2px solid rgb(92, 216, 254);
}
