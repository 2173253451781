* { box-sizing: border-box; }

body {
  background-color: #191919;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Consolas, Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-size: 24px;
}


@media (min-width: 60rem) {
  body {height: 900px;} 
}
